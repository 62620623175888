import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function RoomDetails() {
    const { id } = useParams();  // Get room ID from URL params
    const [room, setRoom] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const roomCache = useRef({});  // Cache to store room data

    useEffect(() => {
        // Check if room data is already cached
        if (roomCache.current[id]) {
            setRoom(roomCache.current[id]);
            setLoading(false);
        } else {
            // If not cached, fetch data from API
            const fetchRoom = async () => {
                try {
                    setLoading(true);
                    const data = (await axios.get(`https://malachite-entertaining-porch.glitch.me/api/rooms/getroom/${id}`)).data;
                    roomCache.current[id] = data;  // Cache the data
                    setRoom(data);
                    setLoading(false);
                } catch (error) {
                    setError(true);
                    console.log(error);
                    setLoading(false);
                }
            };
            fetchRoom();
        }
    }, [id]);

    if (loading) return <h1>Loading...</h1>;
    if (error) return <h1>Error loading room details</h1>;

    return (
        <div className="container">
            <div className="room-details-header">
                {/* Display room image */}
                <img 
                    src={room.imageurl} 
                    alt={room.name} 
                    style={{ 
                        width: '200px', 
                        height: '200px', 
                        objectFit: 'cover', 
                        borderRadius: '8px' 
                    }} 
                />
                
            </div>
            <h1>{room.name}</h1>
            <p><b>Services: </b>{room.services}</p>
            <p><b>Mode: </b>{room.mode}</p>
            <p><b>Location: </b>{room.location}</p>
            <p>{room.description}</p>
            
            <p><b>Contact: </b><a href={`tel:${room.phonenumber}`}>{room.phonenumber}</a></p>
            <p><b>Email: </b><a href={`mailto:${room.email}`}>{room.email}</a></p>
        </div>
    );
}

export default RoomDetails;
