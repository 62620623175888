import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Room from '../components/Room';
import Loader from '../components/Loader';

function Homescreen() {
  const [rooms, setRooms] = useState([]);
  const [duplicateRooms, setDuplicateRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [type, setType] = useState('all');
  const [refresh, setRefresh] = useState(false);  // New state for refresh

  // Fetch rooms data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Check if refresh is false and rooms are cached in sessionStorage
        const cachedRooms = sessionStorage.getItem('rooms');
        if (cachedRooms && !refresh) {
          setRooms(JSON.parse(cachedRooms));
          setDuplicateRooms(JSON.parse(cachedRooms));
          setLoading(false);
        } else {
          // Fetch fresh data from API
          const data = (await axios.get('https://malachite-entertaining-porch.glitch.me/api/rooms/getallrooms')).data;

          // Save data to sessionStorage for caching
          sessionStorage.setItem('rooms', JSON.stringify(data));

          setRooms(data);          // Set the fetched rooms to display all on load
          setDuplicateRooms(data); // Set a copy for filtering
          setLoading(false);
          setRefresh(false);       // Reset refresh state
        }
      } catch (error) {
        setError(true);
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [refresh]);  // Add refresh as a dependency

  // Function to refresh data
  const handleRefresh = () => {
    sessionStorage.removeItem('rooms');  // Clear cache
    setRefresh(true);  // Trigger re-fetch of fresh data
  };

  // Function to filter rooms based on both service type and search query
  function filterRooms(filteredRooms) {
    let tempRooms = filteredRooms;

    // Apply type filter first
    if (type !== 'all') {
      tempRooms = tempRooms.filter(room =>
        room.services && room.services.toLowerCase() === type.toLowerCase()
      );
    }

    // Apply search filter on the filtered results from above
    if (searchKey) {
      tempRooms = tempRooms.filter(room =>
        room.description && room.description.toLowerCase().includes(searchKey.toLowerCase())
      );
    }

    setRooms(tempRooms);
  }

  // Use useEffect to apply filters whenever searchKey or type changes
  useEffect(() => {
    filterRooms(duplicateRooms); // Apply filter on rooms whenever searchKey or type changes
  }, [searchKey, type, duplicateRooms]);  // Trigger filter when searchKey, type, or data changes

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <h1>Error fetching rooms...</h1>;
  }

  return (
    <div className="container">
      <div className="row justify-content-center mt-3">
        <div className="col-md-5">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={searchKey}
            onChange={(e) => {
              setSearchKey(e.target.value);  // Update searchKey
            }}
          />
        </div>
        <div className="col-md-3">
          <select
            className="form-control"
            value={type}
            onChange={(e) => {
              setType(e.target.value);  // Update type
            }}
          >
            <option value="all">All Services</option>
            <option value="plumber">Plumber</option>
            <option value="electrician">Electrician</option>
            <option value="majdoor">Majdoor</option>
            <option value="rajmistri">Raj Mistri</option>
          </select>
        </div>
        <button className="btnrefresh btn-primary mt-2" onClick={handleRefresh}>
          Refresh
        </button>
      </div>

      <div className="row justify-content-center">
        {rooms.map((room) => {
          return (
            <div className="rajaji col-md-9 mt-2" key={room._id}>
              <Room room={room} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Homescreen;
