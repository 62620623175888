import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaSearch, FaGreaterThan } from 'react-icons/fa';
import axios from 'axios';

function Landingscreen() {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    // Fetch all rooms from your existing API
    const fetchProfiles = async () => {
      try {
        const data = (await axios.get('https://malachite-entertaining-porch.glitch.me/api/rooms/getfewrooms')).data;
        setProfiles(data);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };

    fetchProfiles();
  }, []);

  return (
    <div className='hellop'>
      <div className='row landing'>
        <div className="grideer">
          <div className="col-md-12 text-center">
            <img className='bannerimage' src="/image/yozexbanner.png" alt="banner" />
            <div className='yozexhead'>
              <Link to='/home'>
                <button className='btn2 btn-primary'>
                  <span className='spanbtn'>SEARCH ALL LISTING</span> <FaSearch />
                </button>
              </Link>
              <h1 className='apni'>Home Services <FaGreaterThan /></h1>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landingscreen;
