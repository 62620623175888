import React, { useState } from 'react';
import axios from 'axios';

function Register() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [imageurl, setImageUrl] = useState(null);
    const [college, setCollege] = useState('');
    const [services, setServices] = useState('');  // Changed to 'services'
    const [location, setLocation] = useState('');
    const [mode, setMode] = useState('');
    const [phonenumber, setPhoneNumber] = useState('');
    const [agree, setAgree] = useState(false);

    async function addroom() {
        if (
            name &&
            email &&
            description &&
            imageurl &&
            college &&
            services &&  // Using 'services' here
            location &&
            mode &&
            phonenumber &&
            agree
        ) {
            const user = {
                name,
                email,
                description,
                imageurl: imageurl instanceof Blob ? imageurl : dataURLtoBlob(imageurl),
                college,
                services,  // Sending 'services'
                location,
                mode,
                phonenumber,
            };

            try {
                const result = await axios.post('https://malachite-entertaining-porch.glitch.me/api/rooms/getallrooms', user);
                console.log(result.data);
            } catch (error) {
                console.log(error.response.data);
            }
        } else {
            console.log('All fields are required');
        }
    }

    // Convert data URL to Blob
    function dataURLtoBlob(dataURL) {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const b64Data = atob(arr[1]);
        let n = b64Data.length;
        const uint8Array = new Uint8Array(n);
        while (n--) {
            uint8Array[n] = b64Data.charCodeAt(n);
        }
        return new Blob([uint8Array], { type: mime });
    }

    function handleProfilePicChange(event) {
        const file = event.target.files[0];
        if (file) {
            if (file.size <= 25 * 1024) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const base64Image = e.target.result;
                    setImageUrl(base64Image);
                };
                reader.readAsDataURL(file);
            } else {
                alert('Image size should be less than or equal to 25KB');
            }
        }
    }

    return (
        <div>
            <div className="row justify-content-center mt-5">
                <div className="col-md-5">
                    <div className="bs">
                        <h2>Register</h2>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input
                            className="form-control"
                            placeholder="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{ resize: 'none' }}
                        />
                        <input
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleProfilePicChange}
                        />
                        {imageurl && (
                            <img
                                src={imageurl}
                                alt="Profile Pic"
                                width="100"
                            />
                        )}

                        <input
                            type="text"
                            className="form-control"
                            placeholder="College (Highest Degree)"
                            value={college}
                            onChange={(e) => setCollege(e.target.value)}
                        />
                        <select
                            className="form-control"
                            value={services}
                            onChange={(e) => setServices(e.target.value)}
                        >
                            <option value="">Select Service</option>
                            <option value="plumber">Plumber</option>
                            <option value="electrician">Electrician</option>
                            <option value="majdoor">Majdoor</option>
                            <option value="rajmistri">Raj Mistri</option>
                        </select>

                        <input
                            type="text"
                            className="form-control"
                            placeholder="Location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                        <select
                            className="form-control"
                            value={mode}
                            onChange={(e) => setMode(e.target.value)}
                        >
                            <option value="">Select Mode</option>
                            <option value="Offline">Offline</option>
                            <option value="Online">Online</option>
                        </select>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile Number"
                            value={phonenumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={agree}
                                onChange={() => setAgree(!agree)}
                            />
                            <label className="form-check-label">
                                I hereby confirm to share my details with Apni Vidya
                            </label>
                        </div>
                        <button className="btn btn-primary mt-3" onClick={addroom}>
                            Register
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
