import React from 'react';
import { Link } from 'react-router-dom';

function Room({ room }) {
    return (
        <div className='roomstyle row bs'>
            <div className="col-md-4">
                <img src={room.imageurl} className='smallimg' alt={room.name} />
            </div>
            <div className="col-md-7">
                <h1>{room.name}</h1>
                <p>{room.college}</p>
                <h4><b>Services: </b>{room.services}</h4>
                <h4><b>Mode: </b>{room.mode}</h4>
                <h4><b>Location: </b>{room.location}</h4>

                <div style={{ float: 'right' }}>
                    {/* Pass the correct room._id */}
                    <Link to={`/profile/${room._id}`}>
                        <button className='btn btn-primary'>View Details</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Room;
